import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/token'
import { setStorage, getStorage, removeStorage } from '@/utils/storage'
const USER_INFO = 'qiaoqiaoa_userinfo'
const SHARE_UID = 'qiaoqiaoa_share_uid'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(), // 获取token
    userInfo: getStorage(USER_INFO), // 用户信息
    cache_user: getStorage(USER_INFO), // 缓存中的用户信息
    shareUserInfo: {}
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo,
    shareUserInfo: state => state.shareUserInfo,
    cache_userInfo: state => state.cache_user
  },
  mutations: {
    LOGIN (state, token) {
      // 更新 存储token
      state.token = token
      setToken(token)
    },
    LOGOUT (state) {
      state.token = ''
      removeToken()
      removeStorage(USER_INFO)
    },
    // 保存路由的name，来判断页面是否需要被缓存
    SAVE_NAME (state, name) {
      state.routerName = name
    },
    // 从缓存中获取shareId
    SAVE_SHARE_ID (state, shareId) {
      setStorage(SHARE_UID, shareId)
    },
    // 存储用户信息，及 存储用户信息
    SET_USER_INFO (state, userInfo) {
      state.userInfo = userInfo
      // state.cache_user = userInfo
      // console.log('授权之后获取到的userinfo：：：', userInfo)
      setStorage(USER_INFO, userInfo)
    }

  },
  actions: {
  },
  modules: {
  }
})
