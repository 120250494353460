// 封装3个方法，专门操作token
import { setStorage, getStorage, removeStorage } from '@/utils/storage'
const key = 'qiaoqiaoa-token'

// 设置
export const setToken = (token) => {
  setStorage(key, token)
}
export const getToken = () => getStorage(key)

// 移除
export const removeToken = () => {
  removeStorage(key)
}
