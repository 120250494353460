// 封装本地存储的方法
// 整个项目使用localStorage,sessionStorage，还是cookie
// 只需要改这里就行
// 封装：为了统一管理，方便以后替换和修改
export const setStorage = (key, value) => {
  if (typeof (value) === 'string') {
    localStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const getStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch {
    return localStorage.getItem(key)
  }
}

export const removeStorage = (key) => {
  localStorage.removeItem(key)
}
