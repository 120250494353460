import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/token'
import { getStorage, removeStorage } from '@/utils/storage'
import { wechatAuth } from '@/api/weixin'
// import store from '@/store'

const URL_BACK = 'back_url'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/')
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('@/views/Search/')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import('@/views/Redirect/')
  },
  {
    path: '/visit',
    name: 'visit',
    component: () => import('@/views/My/VisitList')
  },
  {
    path: '/vip',
    name: 'vip',
    component: () => import('@/views/My/VipList')
  },

  {
    path: '/fans',
    name: 'fans',
    component: () => import('@/views/My/FansList')
  },
  {
    path: '/payList',
    name: 'payList',
    component: () => import('@/views/My/PayList')
  },
  {
    path: '/searchResult/:kw',
    name: 'searchResult',
    component: () => import('@/views/Search/SearchResult')
  },
  {
    path: '/articleInfo',
    name: 'articleInfo',
    component: () => import('@/views/ArticleInfo')
  },
  {
    path: '/userPro',
    name: 'userPro',
    component: () => import('@/views/EditUserPro/')
  },
  {
    path: '/',
    redirect: '/home', // 这里默认打开首页（layout下面的home)
    component: () => import('@/views/Layout'),
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/Home/')
        // meta: {
        //   scrollT: 0// 保存首页离开时，滚动条的位置
        // }没用上
      },
      {
        path: 'my',
        name: 'my',
        component: () => import('@/views/My/')
      },

      {
        path: 'share',
        name: 'share',
        component: () => import('@/views/Share/')
      }

    ]
  }
]

const router = new VueRouter({
  routes
})

// 全局前置守卫,路由在真正跳转之前执行此函数
router.beforeEach((to, from, next) => {
  const href = window.location.href
  if (href.indexOf('/?code') > -1) {
    // 这块判断处理微信授权回调生成的URL不支持#hash访问的问题
    const urlArr = href.split('/?')
    const rightUrlArr = urlArr[1].split('#/')
    const queryObj = {}
    // 获取code和state参数
    rightUrlArr[0]
      .split('&')
      .map((item) => {
        const splitStr = item.split('=')
        return {
          key: splitStr[0],
          value: splitStr[1]
        }
      })
      .forEach((item) => {
        queryObj[item.key] = item.value
      })

    // 取出code，提交给后台，转换成相应的用户信息并返回
    wechatAuth({ code: queryObj.code }).then(res => {
      if (getToken()) {
        const url = getStorage(URL_BACK)
        console.log('回退url', url)
        removeStorage(URL_BACK)
        location.href = url
      }
    })
  } else {
    next()
  }

  // 有Token不能去登录页，无token，需要用户权限的才能去登录页
  // 如果已经登录了，不要切换到登录页面,?的作用，本地没有值就显示空
  if (getToken()?.length > 0 && to.path === '/login') {
    next('/home')// 留在原地
  } else {
    next()// 其他情况放行
  }

  // }
})

export default router
