<template>
  <div >
    <!--exclude不需要缓存的一级路由页面，这个名字就是组件的名字  -->
    <keep-alive exclude="SearchIndex,SearchResult,ArticleInfo,EditUserPro">
      <router-view/>
    </keep-alive>

  </div>
</template>

<script>

export default {

}
</script>

<!-- 暂时先不弄scss了，依赖安装不上，正式上线后再处理 -->
<!-- <style lang="scss" scoped>
$uni-color-primary: #5677fc;
$uni-color-success: #19be6b;
$uni-color-warning: #ff7900;
$uni-color-yellow: #F6B502;
$uni-color-error: #EB0909;
$uni-color-blue: #2a8dff;
$uni-color-black: #000000;
$uni-color-white: #ffffff;
$uni-color-pink:#f74d54;
$uni-color-link:#586c94;
$uni-color-red:#FF5257;
$uni-color-zise:#787CF8;
$uni-color-qianlan:#e2f4fe;
$uni-color-orange:#ffa500;
$uni-color-juhong:#FF6A00;

/* 文字基本颜色 */
$uni-text-color:#333;//基本色
$uni-text-color-inverse:#fff;//反色
$uni-text-color-grey:#ababb3;//辅助灰色，如加载更多的提示信息
$uni-text-color-grey1:#a8abb3;
$uni-text-color-grey2:#bbbecc;
$uni-image-bg:#f6f7f8;
$uni-text-color-placeholder: #bfbfbf;
$uni-text-color-disable:#c0c0c0;

/* 背景颜色 */
$uni-bg-color:#F5F6F7;
$uni-bg-color-grey:#fafafa;
$uni-bg-color-hover:#f1f1f1;//点击状态颜色
$uni-bg-color-mask:rgba(0, 0, 0, 0.4);//遮罩颜色

/* 边框颜色 */
$uni-border-color:#eaeef1;

/* 尺寸变量 */

/* 文字尺寸 */
$uni-font-size-sm:24rpx;
$uni-font-size-base:28rpx;
$uni-font-size-lg:32rpx;

/* 图片尺寸 */
$uni-img-size-sm:40rpx;
$uni-img-size-base:52rpx;
$uni-img-size-lg:80rpx;

/* Border Radius */
$uni-border-radius-sm: 4rpx;
$uni-border-radius-base: 6rpx;
$uni-border-radius-lg: 12rpx;
$uni-border-radius-circle: 50%;

/* 水平间距 */
$uni-spacing-row-sm: 10rpx;
$uni-spacing-row-base: 20rpx;
$uni-spacing-row-lg: 30rpx;

/* 垂直间距 */
$uni-spacing-col-sm: 8rpx;
$uni-spacing-col-base: 16rpx;
$uni-spacing-col-lg: 24rpx;

/* 透明度 */
$uni-opacity-disabled: 0.3; // 组件禁用态的透明度

/* 文章场景相关 */
$uni-color-title: #2C405A; // 文章标题颜色
$uni-font-size-title:40rpx;
$uni-color-subtitle: #555555; // 二级标题颜色
$uni-font-size-subtitle:36rpx;
$uni-color-paragraph: #3F536E; // 文章段落颜色
$uni-font-size-paragraph:30rpx;

$dmap:(s:flex-start,e:flex-end,c:center);
$fx:top,right,bottom,left;
@mixin rev-flex($direction) {
	flex-direction: $direction;
	&-r{
		flex-direction: #{$direction}-reverse;//ubv-r纵向 反向排列
	}
	@each $key, $value in $dmap {
		&-#{$key}{
			@if $direction=='column' {
				align-items:$value;
			} @else {
				justify-content:$value;
			}
		}
	}
}
.u-t{
	align-items: baseline;
}
.u-t-c{
	align-items: center;
}
.tx-dian {
	display:box;
	line-clamp:2;
	box-orient:vertical;
	text-overflow:ellipsis;
	overflow:hidden; color:#3d4245;
}
.no-warp{
  text-overflow:ellipsis;
  overflow:hidden;
  white-space: nowrap;
}
.tx-{
	&l{text-align: left;}
	&c{text-align: center;}
	&r{text-align: right;}
}
.ub{
	display: flex;
	//排列方向
	&h{
		@include rev-flex(row);//.ubh 横向排列 ubh-r横向 反向排列
	}
	&v{
		@include rev-flex(column);//.ubv 纵向排列 ubv-r纵向 反向排列
	}
	&-sa{
		justify-content: space-around;
	}
	&-sb{
		justify-content:space-between;
	}
	&-warp{
		flex-wrap: wrap;
	}
	&-c{
		align-content: center;
	}
	&c{
		display: inline-flex;
		align-items: center;
		justify-content:center;
	}
}
@each $key, $value in $dmap {
	.uf-#{$key}{
		align-self:$value;
	}
}
.ub{
	&-st{
		align-items: stretch;
	}
}
.uf{
	&-st{
		align-self:stretch;
	}
}
@for $i from 0 through 4 {
	.uf#{$i}{
		flex-grow:$i;
	}
}

.rel{
	position: relative;
}
.abs{
	position: absolute;
}

page{
	height: 100%;
	background-color: $uni-bg-color;
}
.sticky{
	position: sticky;
}
.scroll-top{
	position: fixed;
	right: 30upx;
	bottom: 200upx;
	font-size: $uni-font-size-sm;
	z-index: 99;
	&:before{
		font-size: $uni-font-size-lg * 2;
		display: block;
		margin-bottom: 10upx;
	}
	&-hover:before{
		color: rgba($color: $uni-color-red, $alpha: 0.6);
	}
}
.bg-gradual-red {
	background-image: linear-gradient(45deg, #f43f3b, #ec008c);
	color: #ffffff;
}

.bg-gradual-orange {
	background-image: linear-gradient(45deg, #ff9700, #ed1c24);
	color: #ffffff;
}
$jb:(lan-zi:(#5496ff,#617ef8,#7658ee,#8739e6),lanlan:(#59c2ff,#41a7f6,#2789eb,#1270e3),
fenzi:(#fb60de,#e54ee0,#c936e3,#b525e5),huang:(#ffdb41,#ffc846,#ffad4e,#ff9156),hong:(#ff7c6e,#fc6473,#f73f7c,#f42377));
@each $key, $value in $jb {
	.bg-jb-#{$key}{
		background-image: linear-gradient(135deg, nth($value, 1),nth($value, 2),nth($value, 3),nth($value, 4));
		color: #FFFFFF;
		&.shadow{
			box-shadow: 5upx 5upx 30upx rgba($color: nth($value, 4), $alpha: 0.5);
		}
	}
}

$jb:(red:(#f43f3b, #ec008c),blue:(#0081ff, #1cbbb4),pink:(#ec008c, #6739b6),purple:(#9000ff, #5e00ff),green:(#39b54a, #8dc63f),orange:(#ff9700, #ed1c24));
@each $key, $value in $jb {
	.bg-jb-#{$key}{
		background-image: linear-gradient(45deg, nth($value, 1),nth($value, 2));
		color: #FFFFFF;
		&.shadow{
			box-shadow: 0upx 20upx 40upx rgba($color: nth($value, 2), $alpha: 0.5);
		}
	}
}

$colorArr:(bai:#FFFFFF,blue:$uni-color-blue,anhuang:#e6cb95,purple:#5e00ff,orange:$uni-color-orange,qianlan:$uni-color-qianlan,success:$uni-color-success,red:$uni-color-red,red2:#ff616e,juhong:$uni-color-juhong,zise:$uni-color-zise,yellow:$uni-color-yellow,gray-3:#333333,gray-6:#666666,gray-9:#999999,gray-c:#cccccc,gray:$uni-text-color-grey,gray1:$uni-text-color-grey1,gray2:$uni-text-color-grey2,gray3:#f9f9f9);
@each $key, $value in $colorArr {
	.bg-#{$key}{
		background-color:$value;
		&.shadow{
			box-shadow: 0rpx 20rpx 40rpx rgba($color: $value, $alpha: 0.5);
		}
		&.hover{
			background-color:rgba($color: $value, $alpha: 0.6);
		}
	}
	.text-#{$key}{
		color:$value;
		&.hover{
			color:rgba($color: $value, $alpha: 0.6);
		}
	}

	.border-#{$key}{
		border:1rpx solid $value;
		&.hover{
			border-color:rgba($color: $value, $alpha: 0.6);
		}
	}
	@each $j in $fx {
		.border-#{$j}-#{$key} {
			border-#{$j}:1rpx solid $value;
		}
	}
}
.bai-shadow{
	box-shadow: 5upx 15upx 30upx rgba($color: #ccc, $alpha: 0.5);
}

.iconfont{
	&-hover{
		background-color: $uni-bg-color-hover;
	}
}
.item-hover{
	background-color: $uni-bg-color-hover;
}

@for $i from 0 to 10 {
	& .br#{$i}{
		border-radius: 10rpx + $i * 5;
	}
}

.m-line{text-decoration: line-through;}
$tx:(0:$uni-font-size-sm, 1:$uni-font-size-base, 2:$uni-font-size-lg, 3:45upx, 4:60upx,5:80upx);
@each $k,$v in $tx {
	.t-s#{$k} {
		font-size:$v;
	}
}

@for $i from 1 through 5 {
	.m{
		&#{$i} {
			margin:$i*10upx;
		}
		@each $j in $fx {
			&-#{$j}#{$i} {
				margin-#{$j}:$i*10upx;
			}
		}
	}
	.p{
		&#{$i} {
			padding:$i*10upx;
			box-sizing: border-box;
		}
		@each $j in $fx {
			&-#{$j}#{$i} {
				padding-#{$j}:$i*10upx;
			}
		}
	}
}

.round{
	border-radius: 100%;
}

</style> -->
