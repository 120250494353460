// import router from '@/router'
import axios from 'axios'
import { Toast } from 'vant'// 封装了不能直接引入vant里面的了
// import Toast from '@/ui/toast'
// import { getToken, removeToken, setToken } from './token'
import { getToken, removeToken, setToken } from './token'
import { getNewToken } from '@/api/user'
import store from '@/store'

import router from '@/router'

// const baseURL = 'http://toutiao.itheima.net'
const HOST = 'https://api.qiaoqiaoa.com/'
const MODEL = 'Share'
const baseURL = HOST + MODEL
const request = axios.create({
  baseURL: baseURL
})

export function xhr (url) {
  console.log('xhr', url)
  return axios.get(url)
  // return Promise((resolve, reject) => {
  //   axios.get(url).then(res => {
  //     console.log('xhr in', res)
  //     resolve(res)
  //   }).catch(e => {
  //     reject(e)
  //   })
  // })
}

// 请求拦截器 https://github.com/axios/axios#interceptors
request.interceptors.request.use(function (config) {
  // 如果用户已登录，统一给接口设置 token 信息
  // 在发送请求之前做些什么
  // 目标：统一携带token
  // 判断本地有token再携带，判断具体api里如果没有携带authorization，我再添加
  // 未定义叫undefined，null具体的值你得赋予才叫空
  // ？.可选链操作符，如果前面对象里没有length，整个表达式原地返回underfined
  // 如果getToken()在原地有值token字符串，才能调用length获取长度
  if (getToken()?.length > 0 && config.headers.Authorization === undefined) {
    config.headers.Authorization = `${getToken()}`
  }
  console.log(config)
  // 处理完之后一定要把 config 返回，否则请求就会停在这里 config:本次请求配置对象
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// 响应拦截器
request.interceptors.response.use(function (response) {
  if (response?.data?.result?.token) {
    // 返回的数据带着token了，需要更新token

    store.commit('LOGIN', response.data.result.token)
    store.commit('SET_USER_INFO', response.data.result.data)
  }
  return response.data.result
}, async function (error) {
  // 超过2XX的状态码都会进入这里
  console.dir(error)// 错误对象
  if (error.response.status === 401) {
    Toast.fail('身份已过期')
    removeToken()// 先清除token，才能让路由守卫判断失效，放行去登录页
    // router.replace(`/login?path=${router.currentRoute.fullPath}`)// 第一种方法强制跳转登录页面，用户有感知，体验不好

    // 第二种，用refresh_token换回新的token用户可继续使用，js代码实现，用户无感知
    const res = await getNewToken()
    // 1.新拿回来的 token,需要更新到本地、
    setToken(res.data.data.token)
    // 2.更新新的token在请求头里
    error.config.headers.Authorization = `${res.data.data.token}`
    // 3.未完成的这次请求，再次发起
    // error.config就是上一次请求的配置对象
    // 结果我们要return回原本逻辑页面调用的地方，这样就不会调用下面的return Promise.reject(error)
    return request(error.config)
  } else if (error.response.status === 500 && error.config.url === '/v1_0/authorizations' && error.config.method === 'put') {
    // 刷新的refresh_token也过期了
    Toast.fail('身份已过期')
    removeToken()
    localStorage.clear()// 清除localStorage所有值
    router.replace(`/login?path=${router.currentRoute.fullPath}`)
  }
  return Promise.reject(error)
})

export default request
