import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Lazyload } from 'vant'
import directiveObj from './utils/directive'
import { isWeixin } from './utils/tools'
import 'vant/lib/index.css'
// 引入全部样式,不能再加单独的样式了，样式会冲突
import 'vant/lib/index.less'
import 'amfe-flexible'
// import './utils/day'
const ver = '1.23.07'
const build = '1823051'
const isWeChat = isWeixin()
if (isWeChat) {
  const module = () => import('@/utils/public')
  module().then(Module => {
    Module.oAuth()
  })
}
Vue.use(Vant)
Vue.use(Lazyload)
Vue.config.productionTip = false
Vue.prototype.$ver = ver
Vue.prototype.$build = build

// 执行目标对象里的install方法，并传入Vue类
Vue.use(directiveObj)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
