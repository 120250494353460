// import wx from 'weixin-js-sdk'
import request from '@/utils/request'
// 微信授权登录公众号网页步骤
// 第1步，先判断本地是否有token，决定是不是需要登录
// 第2步，如果需要登录，拿服务器获取code
// 第3步，
// 先获取微信公众号的配置信息
export const getWechatConfig = () => {
  const params = { url: location.href.split('#')[0] }
  return request.post('User/signConf', params
  )
}

export const wechatAuth = (params) => {
  return request.post('User/auth',
    params
  )
}
